<template>
  <div class="base-setup" @click="showIconSelect = false">
    <el-form ref="baseSetting" :model="setup" label-position="top" label-width="80px">
      <el-form-item label="审批名称" :rules="getRule('请输入表单名称')" prop="template_name">
        <a-input v-model="setup.template_name" size="default" placeholder="请输入名称" :max-length="18"></a-input>
      </el-form-item>
      <el-form-item v-if="false" label="所在分组" :rules="getRule('请选择表单分组')" class="group" prop="groupId">
        <a-select v-model="setup.groupId" placeholder="请选择分组" size="default">
          <a-select-option
            v-for="(op, index) in fromGroup"
            :key="index"
            v-show="op.id > 1"
            :label="op.name"
            :value="op.id"></a-select-option>
        </a-select>
        <el-popover placement="bottom-end" title="新建表单分组" width="300" trigger="click">
          <el-input size="medium" v-model="newGroup" placeholder="请输入新的分组名">
            <el-button slot="append" size="medium" type="primary" @click="addGroup">提交</el-button>
          </el-input>
          <el-button icon="el-icon-plus" slot="reference" size="medium" type="primary">新建分组</el-button>
        </el-popover>
      </el-form-item>
      <el-form-item label="所属分类" :rules="getRule('请选择审批所属分类')">
        <el-select
          v-model="setup.settings.notify.types"
          value-key="name"
          placeholder="选择审批所属分类"
          style="width: 100%;"
          size="medium"
          clearable
          collapse-tags>
          <el-option v-for="(wc, index) in notifyTypes" :label="wc.name" :key="index" :value="wc"></el-option>
        </el-select>
        <el-input
          size="medium"
          v-if="false"
          v-model="setup.settings.notify.title"
          style="width: 68%; float:right;"
          placeholder="消息通知标题"></el-input>
      </el-form-item>
      <el-form-item label="谁可以管理此表单" v-if="false">
        <el-select
          v-model="setup.settings.admin"
          @click.native="selectUser('admin')"
          value-key="name"
          class="select-u"
          placeholder="请选择可以管理此表单的人员"
          size="medium"
          clearable
          multiple>
          <el-option v-for="(wc, index) in setup.settings.admin" :label="wc.name" :key="index" :value="wc"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="表单说明">
        <a-textarea
          placeholder="请输入表单说明"
          v-model="setup.remark"
          type="textarea"
          show-word-limit
          :autosize="{ minRows: 2, maxRows: 5}"
          :max-length="300">
        </a-textarea>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getFormGroups, updateGroup } from '@/api/design'

export default {
  name: 'FormBaseSetting',
  data () {
    return {
      nowUserSelect: null,
      select: [],
      newGroup: '',
      fromGroup: [],
      notifyTypes: [
        { type: 'water_bill', name: '水费账单' },
        { type: 'electricity_bill', name: '电费账单' },
        { type: 'rent_bill', name: '租金账单' },
        { type: 'property_bill', name: '物业账单' },
        { type: 'guarantee_bill', name: '保证金账单' },
        { type: 'contract_bill', name: '合同' }
      ],
      rules: {
        template_name: [{}],
        groupId: []
      }
    }
  },
  computed: {
    setup () {
      return this.$store.state.shenpi.design
    }
  },
  mounted () {
    // this.getGroups()
  },
  methods: {
    getRule (msg) {
      return [{ required: true, message: msg, trigger: 'blur' }]
    },
    getGroups () {
      getFormGroups().then(rsp => {
        this.fromGroup = rsp.data
      }).catch(err => this.$message.error('获取分组异常:' + err.response.data))
    },
    addGroup () {
      if (this.newGroup.trim() !== '') {
        updateGroup({ name: this.newGroup.trim() }, 'post').then(rsp => {
          this.$message.success(rsp.data)
          this.getGroups()
        }).catch(err => this.$message.error(err.response.data))
      }
    },
    selected (select) {
      this.$set(this.setup.settings, this.nowUserSelect, select)
      // this.setup[this.nowUserSelect] = select
    },
    selectUser (key) {
      this.select = this.setup.settings[key]
      this.nowUserSelect = key
      // this.$refs.orgPicker.show()
    },
    validate () {
      this.$refs.baseSetting.validate()
      const err = []
      if (!this.$isNotEmpty(this.setup.template_name) || this.setup.template_name === '') {
        err.push('表单名称未设置')
      }
      if (!this.$isNotEmpty(this.setup.settings.notify.types)) {
        err.push('所属分类未设置')
      }
      // if (this.setup.settings.notify.types.length === 0) {
      //   err.push('审批所属分类未设置')
      // }
      return err
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-select-dropdown {
  display: none;
}

.icon-select {
  display: flex;
  flex-wrap: wrap;

  i {
    cursor: pointer;
    font-size: large;
    padding: 10px;
    max-width: 38px !important;

    &:hover {
      box-shadow: 0 0 10px 2px #C2C2C2;
    }
  }
}

/deep/ .select-u {
  width: 100%;
}

.base-setup {
  overflow: auto;
  margin: 0 auto;
  width: 600px;
  height: calc(100vh - 105px);
  background: #ffffff;
  margin-top: 10px;
  padding: 15px 20px;

  i:first-child {
    position: relative;
    cursor: pointer;
    font-size: xx-large;
    color: #ffffff;
    border-radius: 10px;
    padding: 10px;
  }

  .change-icon {
    margin-left: 20px;

    span {
      font-size: small;
      color: #7a7a7a;
      margin-right: 15px;
    }

    i {
      cursor: pointer;
      color: #7a7a7a;
      font-size: x-large;
    }
  }

  /deep/ .el-form-item__label {
    padding: 0;
    font-weight: bold;
  }

  /deep/ .el-form-item {
    margin-bottom: 5px;
  }
}

/deep/ .group {
  .el-select {
    width: calc(100% - 130px);
  }

  .el-button {
    margin-left: 10px;
    width: 120px;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f8f8f8;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #e8e8e8;
}
</style>

<template>
  <div>
    <el-form label-width="90px" v-if="form.name !== 'SpanLayout'">
      <el-form-item label="表单名称">
        <el-input size="small" clearable v-model="form.title" />
      </el-form-item>
      <component :is="form.name" v-model="form.props" />
      <el-form-item label="必填项">
        <el-switch v-model="form.props.required"></el-switch>
      </el-form-item>
      <el-form-item label="可打印">
        <el-switch v-model="form.props.enablePrint"></el-switch>
      </el-form-item>
    </el-form>
    <el-empty v-else description="当前组件不支持配置"></el-empty>
  </div>

</template>

<script>
// import TextInput from '@/views/a-approve/common/form/config/TextInputConfig'
// import NumberInput from '@/views/a-approve/common/form/config/NumberInputConfig'
// import AmountInput from '@/views/a-approve/common/form/config/AmountInputConfig'
// import TextareaInput from '@/views/a-approve/common/form/config/TextareaInputConfig'
// import MultipleSelect from '@/views/a-approve/common/form/config/SelectInputConfig'
// import SelectInput from '@/views/a-approve/common/form/config/SelectInputConfig'
// import DateTime from '@/views/a-approve/common/form/config/DateTimeConfig'
// import DateTimeRange from '@/views/a-approve/common/form/config/DateTimeRangeConfig'
// import ImageUpload from '@/views/a-approve/common/form/config/ImageUploadConfig'
// import FileUpload from '@/views/a-approve/common/form/config/FileUploadConfig'
// import Description from '@/views/a-approve/common/form/config/DescriptionConfig'
// import MoneyInput from '@/views/a-approve/common/form/config/MoneyInputConfig'
// import DeptPicker from '@/views/a-approve/common/form/config/OrgPickerConfig'
// import UserPicker from '@/views/a-approve/common/form/config/OrgPickerConfig'
// import TableList from '@/views/a-approve/common/form/config/TableListConfig'

export default {
  name: 'FormComponentConfig',
  components: {
    // TextInput,
    // NumberInput,
    // AmountInput,
    // TextareaInput,
    // SelectInput,
    // MultipleSelect,
    // DateTime,
    // DateTimeRange,
    // ImageUpload,
    // FileUpload,
    // Description,
    // MoneyInput,
    // DeptPicker,
    // UserPicker,
    // TableList
  },
  props: {},
  computed: {
    form () {
      return this.$store.state.selectFormItem
    }
  },
  data () {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>

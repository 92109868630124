var render, staticRenderFns
import script from "./ProcessTree.vue?vue&type=script&lang=js"
export * from "./ProcessTree.vue?vue&type=script&lang=js"
import style0 from "./ProcessTree.vue?vue&type=style&index=0&id=bcc80e5a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcc80e5a",
  null
  
)

export default component.exports